<template>
  <div class="order_detail">
    <van-nav-bar title="订单详情" left-arrow @click-left="onClickLeft" />

    <div class="detail-status">
      <img src="../../assets/dengdai.png" />

      <div>
        <span>{{ detail.order_state_zh_cn }}</span>

        <span class="hips" v-if="!detail.paid_at">超 30 分钟未支付将自动关闭</span>

        <div v-if="logisticsInfo.LogisticCode" class="logistics_msg" @click="goToLogisticsInfo">
          {{ logisticsInfo.Name }}：{{ logisticsInfo.LogisticCode }}
        </div>
      </div>

      <div v-if="logisticsInfo.State" @click="goToLogisticsInfo" class="logistics_state">
        {{ logisticsInfo.State }}
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="detail-user" v-if="detail.address">
      <div class="user_people">
        <span class="people-name">收件人：{{ detail.address.contact_name }}</span>

        <span class="people-phone">{{ detail.address.contact_phone }}</span>
      </div>

      <div class="user_address">收货地址：{{ detail.address.address }}</div>
    </div>

    <div class="detail-item">
      <div class="item-msg" v-for="(item, index) in detail.items" :key="index" @click="goodsDetail(item.product)">
        <div class="msg_box">
          <img :src="item.product_snap.image_url" />

          <div class="msg_detail">
            <div class="msg-name">{{ item.product_snap.title }}</div>

            <div class="msg-pay">
              <span class="pay-real-pay">￥{{ item.price }}</span>

              <span class="pay-num">×{{ item.amount }}</span>
            </div>
          </div>
        </div>

        <van-button v-if="canRefund" @click.stop="refundClick(item)" class="refund_btn" type="default">
          {{ refund_status[item.refund_status] }}
        </van-button>
      </div>

      <div class="pay-item">
        <span>商品金额</span>

        <span class="pay-item-val">￥{{ product_price }}</span>
      </div>

      <div class="pay-item" v-if="detail.discount">
        <span>折扣优惠</span>

        <span class="pay-item-val">-￥{{ Number(detail.discount.coupon).toFixed(2) }}</span>
      </div>

      <div class="express-fare" v-if="detail.express_fare">
        <span>运费</span>

        <span class="express-fare-val">￥{{ detail.express_fare }}</span>
      </div>

      <div class="pay-item" v-if="detail.discount">
        <span>积分抵现</span>

        <span class="pay-item-val">-￥{{ Number(detail.discount.point).toFixed(2) }}</span>
      </div>

      <div style="text-align: right; margin-top: 10px" v-if="!detail.closed">
        <span style="font-size: 18px; color: #ff373f" v-if="detail.paid_at">
          <span style="font-size: 13px; color: #333">实付款：</span>
          ￥{{ detail.total_amount }}
        </span>

        <span style="font-size: 18px; color: #ff373f" v-else>
          <span style="font-size: 13px; color: #333">待付款：</span>
          ￥{{ detail.total_amount }}
        </span>
      </div>

      <button
        v-if="detail.order_state_zh_cn == '已发货' || detail.order_state_zh_cn == '已发货(部分退款成功)'"
        @click="openDialog('是否确认收货？', 'receivedOrder')"
        class="footer_btn"
      >
        确认收货
      </button>

      <button
        v-if="detail.order_state_zh_cn == '待付款'"
        @click="openDialog('是否取消订单？', 'cancelOrder')"
        class="footer_btn"
      >
        取消订单
      </button>
    </div>

    <div class="detail-orderMsg" v-if="detail.points">
      <div v-if="detail.points.find((e) => e.is_add)">
        商城积分：获得{{ detail.points.find((e) => e.is_add)['amounts'] }}点积分
      </div>

      <div>订单号：{{ detail.no }}</div>

      <div v-if="!detail.closed">付款时间：{{ detail.paid_at }}</div>
    </div>

    <!-- <van-button v-if="detail.order_state_zh_cn == '已发货'" class="received_btn" @click="receivedOrder" block>确认收货</van-button> -->

    <div class="detail-reorder" v-if="!detail.paid_at && !detail.closed">
      <van-submit-bar
        :loading="submitLoading"
        :price="detail.total_amount * 100"
        button-text="去支付"
        @submit="onSubmit"
      />
    </div>

    <van-dialog
      v-model="dialogShow"
      show-cancel-button
      :message="dialogOptions.message"
      cancel-button-text="否"
      @confirm="onConfirm"
    />
  </div>
</template>

<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>

<script>
import wechat from '../../utils/wechat'
import {
  getOrders,
  wechatPay,
  received,
  cancel,
  express,
  expressList,
  getOrderDetail,
  payWithoutMoney,
} from '../../services/order'
import { Toast } from 'vant'
import { toastHttpUnKnownError } from '@/utils/errors'
import { getTimer } from '@/utils/convert'
export default {
  data: function () {
    return {
      id: null,
      canRefund: false,
      detail: {},
      company_id: null,
      submitLoading: false,
      isMiniApp: false,
      refund_status: {
        pending: '申请售后',
        applied: '已申请退款',
        processing: '退款中',
        success: '退款成功',
        failed: '退款失败',
        processing_product_needed: '退款中(需要退货)',
        applied_product_returned: '已申请退款(已退货)',
      },
      dialogShow: false,
      dialogOptions: {
        message: '',
        onConfirm: '',
      },
      logisticsInfo: {
        State: '',
        express: [],
        ship_type: '',
        LogisticCode: '',
      },
    }
  },
  created: function () {
    let { id, company_id } = this.$route.query
    let detial

    this.company_id = company_id

    getOrderDetail(id).then((details) => {
      detial = details.data
      if (detial.paid_at) {
        detial.paid_at = getTimer('YYYY-mm-dd HH:MM:SS', detial.paid_at)
      }
      detial.order_state_zh_cn = this.getOrderStatus(detial)
      this.detail = detial
      // if (
      //   detial.order_state_zh_cn == '已发货' ||
      //   detial.order_state_zh_cn == '已发货(部分退款成功)' ||
      //   this.detail.ship_data != null
      // )
      this.orderExpress()
    })
  },
  computed: {
    product_price: function () {
      if (!this.detail.items) return

      return this.detail.items.reduce((total, curr) => {
        return (Number(total) + Number(curr.price) * curr.amount).toFixed(2)
      }, 0)
    },
    discount: function () {
      // 商品金额 - 实付款 + 运费 = 折扣优惠
      return (this.product_price - this.detail.total_amount + Number(this.detail.express_fare)).toFixed(2)
    },
  },
  mounted: function () {
    this.checkIsMiniApp()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    goodsDetail(item) {
      const { id, type } = item
      let limit = 0
      if (item.time_limit_discount && createTime(item.time_limit_discount.ended_at) > createTime()) {
        limit = 1
      }
      wx.miniProgram.navigateTo({
        url: `/pages/customer/shop/products/detail/detail?id=${id}&isCard=${
          type == 'product' ? 0 : 1
        }&from_user=null&isLimit=${limit}`,
        fail: function (erro) {
          alert(erro)
        },
      })
    },
    getOrderStatus(order) {
      const { closed, paid_at, refund_status, ship_status } = order
      let status
      const ship_status_zh = {
        pending: '待发货',
        delivered: '已发货',
        received: '已完成',
        unverify: '待核销',
      }
      const refund_status_zh = {
        pending: '未退款',
        applied: '已申请退款',
        processing: '退款中',
        success: '退款成功',
        failed: '退款失败',
        part_success: '退款成功',
      }
      if (closed) {
        status = '已取消'
        return status
      }
      if (!paid_at) {
        status = '待付款'
        return status
      }
      // 已付款之后的流程都展示售后按钮
      this.canRefund = true
      // 售后
      if (refund_status !== 'pending') {
        // 其中有已申请退款的商品
        if (refund_status == 'applied') {
          status = refund_status_zh[refund_status]
          return status
        }
        // 当全部退款成功显示退款成功
        status = order.items.every((item) => item.refund_status == 'success')
          ? refund_status_zh[refund_status]
          : order.ship_status == 'delivered' || order.ship_status == 'received'
          ? `${ship_status_zh[ship_status]}（部分${refund_status_zh[refund_status]}）`
          : `${ship_status_zh[order.order_status]}（部分${refund_status_zh[refund_status]}）`
      } else if (ship_status == 'delivered' || ship_status == 'received') {
        status = ship_status_zh[ship_status]
      } else {
        status = ship_status_zh[order.order_status]
      }
      return status
    },
    onSubmit() {
      this.submitLoading = true
      let order = this.detail

      if (order.total_amount == 0) {
        return payWithoutMoney(order.id)
          .then((res) => {
            Toast(res.data.message)
            setTimeout(() => {
              window.location = `/#/order?company_id=${this.company_id}`
            }, 2000)
          })
          .catch((error) => {
            if (error === undefined) return Promise.reject()
            console.error(error)
            switch (error.response.status) {
              case 422:
                Toast(`错误码：${error.response.data.code}，错误信息：${error.response.data.message}`)
                break
              default:
                toastHttpUnKnownError(error)
                break
            }
          })
          .finally(() => {
            this.submitLoading = false
          })
      }
      return wechatPay(order.id)
        .then((res) => {
          let pay_resp = res.data
          let payParam = { orderId: order.id, ...pay_resp }

          if (this.isMiniApp) {
            let url = '/pages/customer/pay/index?payParam=' + encodeURIComponent(JSON.stringify(payParam))
            return wechat.naviToMiniApp(url)
          } else {
            wechat.invokePayment(payParam, () => {
              setTimeout(() => {
                window.location = `/#/order?company_id=${this.company_id}`
              }, 2000)
            })
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              Toast('订单状态不正确')
              break
            default:
              toastHttpUnKnownError(error)
              break
          }
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    checkIsMiniApp() {
      wechat.checkIsMiniApp().then(
        (res) => (this.isMiniApp = res),
        (rej) => ''
      )
    },
    refundClick({ id, refund_status }) {
      this.$router.push({
        path: refund_status == 'pending' ? '/order/refund' : '/order/refund_detail',
        query: {
          id: id,
          is_store: this.detail.address.is_store,
          ship_status: this.detail.ship_status,
        },
      })
    },
    onConfirm() {
      switch (this.dialogOptions.onConfirm) {
        case 'receivedOrder':
          this.receivedOrder()
          break
        case 'cancelOrder':
          this.cancelOrder()
          break
      }
    },
    receivedOrder() {
      let orderId = this.detail.id
      received(orderId)
        .then((res) => {
          Toast('确认收货成功')
          this.$router.go(-1)
        })
        .catch((error) => {
          Toast('确认收货失败')
        })
    },
    cancelOrder() {
      let orderId = this.detail.id
      cancel(orderId)
        .then((resp) => {
          Toast(resp.data.message)
          this.$router.go(-1)
        })
        .catch((error) => {
          Toast('订单取消失败，请刷新重试')
        })
    },
    openDialog(message, onConfirm) {
      this.dialogShow = true
      this.dialogOptions = {
        message: message,
        onConfirm: onConfirm,
      }
    },
    orderExpress() {
      let orderId = this.detail.id
      expressList(orderId)
        .then((resp) => {
          this.logisticsInfo.ship_type = resp.data.ship_type
          if (resp.data.ship_type != 'part') {
            this.logisticsInfo = resp.data.express
            // -1：单号或快递公司代码错误, 0：暂无轨迹，1:快递收件，2：在途中,3：签收,4：问题件 5.疑难件 6.退件签收
            const logisticsState = {
              '-1': '单号或快递公司代码错误',
              0: '暂无轨迹',
              1: '快递收件',
              2: '在途中',
              3: '签收',
              4: '问题件',
              5: '疑难件',
              6: '退件签收',
            }
            this.logisticsInfo.State = logisticsState[this.logisticsInfo.State]
          } else {
            this.detail.ship_status != 'part_delivered'
              ? (this.logisticsInfo.State = '查看物流')
              : (this.logisticsInfo.State = '部分订单已发货')
            this.logisticsInfo.express = resp.data.express
          }
        })
        .catch((error) => {})
    },
    goToLogisticsInfo() {
      console.log(this.logisticsInfo)
      if (
        (this.detail.order_state_zh_cn == '已发货' ||
          this.detail.order_state_zh_cn == '已发货(部分退款成功)' ||
          this.detail.ship_data != null) &&
        this.logisticsInfo.ship_type != 'part'
      ) {
        this.$router.push({
          path: '/order/logistics_detail',
          query: {
            logisticsInfo: JSON.stringify(this.logisticsInfo),
          },
        })
      } else {
        this.$router.push({
          path: '/order/logistics_details',
          query: {
            logisticsInfo: JSON.stringify(this.logisticsInfo),
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.order_detail {
  width: 100%;
  height: 100%;
  background: #f5f5f9;
}
.detail-status {
  height: 70px;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(360deg, rgba(252, 137, 112, 1) 0%, rgba(252, 87, 102, 1) 100%);
  display: flex;
  align-items: center;
}
.detail-status .logistics_msg,
.detail-status .logistics_state {
  font-size: 12px;
  color: #ffffff;
}
.detail-status .logistics_state {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.detail-status .logistics_state > span {
  font-size: 20px;
}
.detail-status > .hips {
  font-size: 12px;
  padding-left: 15px;
}

.detail-status > img {
  width: 22px;
  height: 22px;
  margin-left: 8px;
  margin-right: 8px;
}

.detail-user {
  height: 75px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
}

.detail-user > .user_people {
  margin-bottom: 10px;
}

.user_people > .people-name,
.user_people > .people-phone {
  font-size: 15px;
  color: #323233;
}

.user_people > .people-phone {
  float: right;
}

.detail-user > .user_address {
  font-size: 13px;
  color: #666;
}

.detail-item {
  background: #fff;
  padding: 10px 10px 15px 10px;
  margin-top: 14px;
  overflow: hidden;
}

.detail-item > .item-msg {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}

.detail-item > .item-msg:last-child {
  border-bottom: none;
}

.detail-item > .item-msg img {
  width: 80px;
  height: 80px;
}

.detail-item > .item-msg .msg_detail {
  color: #000;
  flex: 1;
  margin-left: 10px;
}
.item-msg > .msg_box {
  width: 100%;
  display: flex;
}
.item-msg .msg-name {
  font-size: 13px;
  margin-bottom: 24px;
}

.item-msg .msg-pay {
  display: flex;
  justify-content: space-between;
}

.item-msg .msg-pay .pay-real-pay {
  font-size: 15px;
}

.item-msg .msg-pay .pay-num {
  font-size: 12px;
  color: #888;
}

.detail-item > .pay-item {
  font-size: 13px;
  color: #888;
  margin-top: 10px;
}

.detail-item > .pay-item .pay-item-val {
  float: right;
}

.detail-item > .express-fare {
  font-size: 13px;
  color: #888;
  margin-top: 10px;
}

.detail-item > .express-fare .express-fare-val {
  float: right;
}
.detail-item > .footer_btn {
  width: 100px;
  height: 30px;
  color: #ff373f;
  font-size: 16px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid rgba(255, 55, 63, 1);
  float: right;
  margin-top: 8px;
}
.detail-orderMsg {
  background: #fff;
  margin-top: 15px;
  padding: 15px 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #888;
}

.detail-orderMsg > div {
  margin-bottom: 5px;
}

.refund_btn {
  min-width: 65px;
  border: 1px solid #606060;
  color: #333333;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 24px;
  margin-top: 5px;
  border-radius: 3px;
  align-self: flex-end;
  padding: 0 3px;
}

/* 确认收货按钮 */
.received_btn {
  width: 90%;
  margin: 0 auto;
  margin-top: 15px;
  color: #888;
}
</style>
